import {
  boyGame,
  connections,
  finance,
  healthcare,
  innovate,
  build,
} from "../Assets";
export const timeline = [
  {
    title: "Registration Begins",
    desc: "Registration for Showdownn is now open to all eligible participants! Don’t miss the chance to take on exciting challenges and showcase your skills in NIT Durgapur’s premier hackathon. Secure your spot by registering early.",
    date: "14th Nov 21:00 IST",
  },
  {
    title: "Problem Statement Drop",
    desc: "The problem statements for Showdownn will be released, officially kicking off this year’s hackathon. Participants can explore two domains—Healthcare and Finance—each featuring three unique challenges crafted to test creativity, technical skills, and problem-solving abilities. Choose one problem statement from either domain to begin your project, and prepare to submit your prototype and presentation for evaluation.",
    date: "22nd Nov 20:00 IST",
  },
  {
    title: "Registration Closes",
    desc: "Final call to register! This is your last chance to secure your spot and join us for an unforgettable experience. Make sure to complete your registration by this date to be part of the event and take advantage of everything it has to offer. Don’t miss out on this opportunity!",
    date: "31st Dec 22:00 IST",
  },
  {
    title: "Idea Submission Deadline",
    desc: "Finalize and submit your initial PPT and prototype video if possible by this date, showcasing your approach and design direction. Make sure everything’s ready to demonstrate your solution’s potential!",
    date: "31st Dec 22:00 IST",
  },
  {
    title: "Shortlisted Teams Announced & Payment Portal Opens",
    desc: "Check your inbox for the official shortlist! Confirm your spot and prepare for the next stage by completing the payment process. Teams will need to pay ₹1000 as convenience charges for smooth conduction of offline hackathon.",
    date: "1st Jan 18:00 IST",
  },
  {
    title: "Payment Portal Closes",
    desc: "This is the final day to complete payment for shortlisted teams. Ensure all formalities are completed to confirm your participation in the next round.",
    date: "04th Jan 14:00 IST",
  },
  {
    title: "Final Shortlist Teams Released",
    desc: "The final list of teams advancing to the offline hackathon at NIT Durgapur will be announced. Stay tuned to see if your team made it to the in-person round!",
    date: "06th Jan 14:00 IST",
  },
  {
    title: "Hackathon Day - 12 Hours of Pure Innovation",
    desc: "It’s showtime! Join us on campus for an intense, 12-hour coding marathon, where you and your team will build, test, and refine your solution to meet the challenge head-on.",
    date: "18th Jan 08:30 IST",
  },
  {
    title: "Awards Ceremony and Closing Celebration",
    desc: "Celebrate the creativity, hard work, and achievements of all participants! Winners will be announced, and the hackathon will conclude with a closing celebration for everyone involved.",
    date: "19th Jan 10:00 IST",
  },
];

export const faq = [
  {
    question: "Who can participate in Showdownn?",
    answer:
      "Showdownn is open to all college students from any year and specialization. Inter-college and inter-specialization teams are welcome!",
  },
  {
    question: "How many members are allowed per team?",
    answer: "Teams can consist of 1 to 4 members.",
  },
  {
    question: "Is there a registration fee?",
    answer:
      "Registration is free for all eligible participants. However, selected teams for the offline hackathon will need to pay a participation fee: ₹1000 as convenience charges for smooth conduction of the offline hackathon.",
  },
  {
    question: "What is the prize pool for Showdownn?",
    answer:
      "The prize pool is over INR 20,000, along with certificates recognizing your expertise and impact.",
  },
  {
    question: "Will participants receive any goodies or swag?",
    answer:
      "Yes! All eligible participants will receive exclusive Showdownn swag and goodies as a token of appreciation.",
  },
  {
    question: "What are the domains and problem statements for this hackathon?",
    answer:
      "The hackathon will feature two domains: Healthcare and Finance. Each domain includes three unique problem statements designed to test creativity and technical skills.",
  },
  {
    question: "What is the format and duration of the offline hackathon?",
    answer:
      "The offline hackathon will be an intense 12-hour event where teams will develop, test, and polish their solutions.",
  },
  {
    question: "What will be required for the initial submission?",
    answer:
      "Teams must submit an initial PowerPoint presentation (PPT) by the 12th Dec deadline. A working prototype is optional but encouraged to strengthen your submission. Please note that the registration deadline is on 10th Dec.",
  },
  {
    question: "How will teams be shortlisted for the offline round?",
    answer:
      "Teams will be shortlisted based on the quality, feasibility, and creativity of their initial submission. Selected teams will be notified by 14th Dec.",
  },
  {
    question:
      "Where can I find more information on rules, problem statements, and other details?",
    answer:
      "Please visit our website for comprehensive information on rules, problem statements, FAQs, and updates.",
  },
];

 

export const themes = [
  {
    title: "Healthcare",
    subtitle:
      "The aim should be to focus on building an innovative solution addressing the challenges faced in the healthcare field that is effective, user-friendly, and easily accessible to all people.",
    image: healthcare,
  },
  {
    title: "Finance",
    subtitle:
      "The aim should be to develop a groundbreaking solution that tackles financial industry challenges, ensuring it is efficient, intuitive, and accessible to everyone.",
    image: finance,
  },
];

export const whyUs = [
  {
    title: " Unleash Innovation",
    subtitle:
      "At Showdownn 1.0, we're bringing together the brightest minds in tech, design, and entrepreneurship to collaborate on real-world problems. This is more than just a hackathon — it's a platform for turning bold ideas into impactful solutions.",
    image:
      "https://res.cloudinary.com/dogqbjx8a/image/upload/innovate_iytsse.webp",
  },
  {
    title: "Build Your Network",
    subtitle:
      "Connect with like-minded developers, industry professionals, and mentors. Showdownn 1.0 gives you access to invaluable networking opportunities that can take your career to the next level.",
    image:
      "https://res.cloudinary.com/dogqbjx8a/image/upload/connections_ywkg1f.webp",
  },
  {
    title: "Thrilling Challenges, Limited Time",
    subtitle:
      "It's a race against time! In just 12 hours, you'll need to collaborate, build, and innovate to present a solution that stands out. Show us what you can achieve under pressure!",
    image:
      "https://res.cloudinary.com/dogqbjx8a/image/upload/build_zr7yix.webp",
  },
  {
    title: "Real-World Impact",
    subtitle:
      "This isn't just for fun — we're looking for solutions that can make a tangible difference. Whether you're tackling climate change or rethinking healthcare, your projects at Showdownn 1.0 have the potential to go beyond the hackathon.",
    image:
      "https://res.cloudinary.com/dogqbjx8a/image/upload/boyGame_dckqbr.webp",
  },
  {
    title: "Thrilling Challenges, Limited Time",
    subtitle:
      "It's a race against time! In just 12 hours, you'll need to collaborate, build, and innovate to present a solution that stands out. Show us what you can achieve under pressure!",
    image:
      "https://res.cloudinary.com/dogqbjx8a/image/upload/build_zr7yix.webp",
  },
  {
    title: "Thrilling Challenges, Limited Time",
    subtitle:
      "It's a race against time! In just 12 hours, you'll need to collaborate, build, and innovate to present a solution that stands out. Show us what you can achieve under pressure!",
    image:
      "https://res.cloudinary.com/dogqbjx8a/image/upload/build_zr7yix.webp",
  },
];

export const sponsors=[
  {
    text:"POWERED BY",
    link:"https://d8it4huxumps7.cloudfront.net/uploads/images/unstop/branding-guidelines/logos/blue/Unstop-Logo-Blue-Medium.png",
  },
  {
    text:"",
    link:"https://img.icons8.com/?size=120&id=AbQBhN9v62Ob&format=png&color=000000",
  },
]